import { SearchType } from 'utils/enums/SearchType';
import { IParentItem } from 'domain/Base/IParentItem';
import { Router } from 'aurelia-router';
import { AppConfig } from 'app-config';
import { autoinject, observable, LogManager, bindable } from 'aurelia-framework';
import { IProduct } from 'domain/Product/IProduct';
import { Utils } from 'utils/helpers/utils';

export const log = LogManager.getLogger('app.components.extra-row');

@autoinject
export class ExtraRowCustomElement {
  @bindable @observable product: IProduct;

  constructor(
    private appConfig: AppConfig,
    private router: Router
  ) { }

  openDocument(productId: number, document: [string, string], index: number) {
    const data = document[1];
    let win: Window | null;

    if (data && data.toLowerCase().startsWith("http")) {
      win = window.open(data, "_blank");
    } else {
      win = window.open(`${this.appConfig.apiUrl}/Product/${productId}/Document/${index}`, "_blank");
    }

    if (win != null)
      win.focus();
  }

  showGallery(product: IProduct) {
    Utils.showGallery(product);
  }

  loadVehicle(item: IParentItem) {
    this.router.navigateToRoute('catalogueList', { vehicleId: item.id });
  }

  rotateArrow(target: HTMLElement) {
    target.classList.toggle(".active");
  }

  searchOeNumber(query: string) {
    this.router.navigateToRoute('searchResult', {
      query: Utils.encode(query),
      page: 1,
      type: SearchType.TecDoc
    });
  }
}
