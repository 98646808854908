import { bindable } from 'aurelia-framework';

export class OrderSearchBarCustomElement {
  @bindable searchIcon: string;
  @bindable searchPlaceholder: string;

  @bindable searchText: string;
  @bindable searchDateFrom: string;
  @bindable searchDateTo: string;

  hasFocus = true;
}
