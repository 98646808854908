import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CategoryService } from 'services/product/category-service';
import { AppConfig } from 'app-config';
import { Router, NavigationInstruction, PipelineResult } from 'aurelia-router';
import { IParentItem } from 'domain/Base/IParentItem';
import { LogManager, autoinject } from 'aurelia-framework';
import $ from 'jquery';
import 'bootstrap';

export const log = LogManager.getLogger('app.search.categories');

@autoinject
export class Categories {
  // Cache
  categories: IParentItem[];

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig, // used by HTML
    private categoryService: CategoryService,
    private i18n: I18N
  ) { }

  // called only once: when the site is opened (and the user probably isn't logged in yet)
  activate() {
    // If user is already logged in, but refreshed the page
    if (this.appConfig.jwt)
      this.loadCategoryMenu();

    // attached() was too late, the "router:navigation:success" event had already been fired
    this.eventAggregator.subscribe('router:navigation:success', (event: {
      instruction: NavigationInstruction; result: PipelineResult;
    }) => {
      const currentRoute = event.instruction.config.name;
      if (!currentRoute)
        return;

      // Run only after successful login
      if (this.appConfig.jwt && this.categories === undefined)
        this.loadCategoryMenu();
    });
  }

  // #region LOAD

  loadCategoryMenu() {
    this.categoryService.fetchCategories().then(result => {
      this.categories = result;
      this.positionSubmenu();
    }).catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  openCategory(category: IParentItem) {
    let id = category.id;

    if (id == 6 || id == 7 || id == 8 || id == 10) { // Car categories
      this.router.navigateToRoute('categoryManufacturers', { categoryId: category.id });
      return;
    }

    if (id == 54) // 54 is alias for 10
      id = 10;

    this.router.navigateToRoute('categoryProducts', {
      categoryId: id,
      page: 1
    });
  }

  // #endregion

  // #region HELPERS

  positionSubmenu() {
    $(() => {
      function position(element: JQuery<HTMLElement>) {
        if (element.css('top') != "0px")
          return;

        const parentTop = $("#level-1-menu").offset()?.top ?? 0;
        const currentTop = element.offset()?.top ?? 0;

        const containerWidth = $(".container").first().width() ?? 0;
        const parentWidth = $("#level-1-menu").outerWidth() ?? 0;

        const windowHeight = window.innerHeight ?? 0;
        const parentHeight = $("#level-1-menu").innerHeight() ?? 0;

        element.css({
          position: "absolute",
          top: (parentTop - currentTop) + "px",
          left: "100%",
          width: (containerWidth - parentWidth) + "px",
          'min-height': parentHeight + "px",
          'max-height': (windowHeight - parentTop) + "px",
          overflow: "scroll"
        });
      }

      // Submenu click support - (c) https://stackoverflow.com/a/45755948
      $('.dropdown-menu a.dropdown-toggle').on('click', function () {
        if (!$(this).next().hasClass('show'))
          $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');

        const subMenu = $(this).next('.dropdown-menu');
        subMenu.toggleClass('show');
        if (subMenu.hasClass('show'))
          position(subMenu);

        return false;
      });

      $('.level-2-menu .dropdown-item').on('click', function () {
        $(this).parents().removeClass("hover-show show");
      });

      $('.dropdown-menu a.dropdown-toggle').on('mouseenter', function () {
        if ($('#level-1-menu').find('.show').length > 0)
          return;

        const menu = $(this).next();
        if (!menu.hasClass('hover-show')) {
          menu.addClass('hover-show');
          position(menu);
        }
      });
      $('.dropdown-menu a.dropdown-toggle').on('mouseleave', function () {
        if ($(this).next('.dropdown-menu:hover').length == 0)
          $(this).next().removeClass('hover-show');
      });
      $('.level-2-menu').on('mouseleave', function () {
        if ($(this).prev('.dropdown-toggle:hover').length == 0)
          $(this).removeClass('hover-show');
      });
    });
  }

  // #endregion
}
