import { autoinject, observable, bindable, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { IPrice } from 'domain/Product/IPrice';
import { PriceType } from 'utils/enums/PriceType';

export const log = LogManager.getLogger('app.components.price');

@autoinject
export class PriceCustomElement {
  @bindable @observable prices: IPrice[];
  @bindable displayPrice: number | null;

  constructor(
    private i18n: I18N
  ) {}

  // #region MAIN

  pricesChanged() {
    if (this.prices == null)
      Utils.showErrorToast(log, this.i18n.tr('components.price.errors.load'));
  }
  
  hasAmountDependentPrice(prices: IPrice[]) {
    for (const price of prices) {
        switch (price.type) {
          case PriceType.AmountDiscount:
            return true;
          case PriceType.AmountDiscountList:
            return true;
        }
    }
    return false;
  }

  // #endregion
}
