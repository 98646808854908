import { IParentItem } from 'domain/Base/IParentItem';
import { bindable } from 'aurelia-framework';

export class AccordionCustomElement {
  @bindable list: IParentItem[];
  @bindable parentId: number;
  @bindable clickFunction: any;
  @bindable filter: string | undefined;

  click(item: IParentItem) {
    this.clickFunction({ item: item });
  }

  getClass(item: IParentItem): string {
    let classes = "";

    if (!item.children || !item.children.length) {
      classes += "accordion-child";

      if (this.filter)
        classes += " matching-item";
    }

    return classes;
  }
}
