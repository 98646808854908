import { LogManager } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';

export var log = LogManager.getLogger('app.components.pager');

export class PagerCustomElement {
  @bindable currentPage: number = 1;
  @bindable lastPage: number = 1;

  // the number of first and last pages to be displayed
  @bindable extremePagesLimit: number = 2;
  // the number of pages that are displayed around the active page
  @bindable nearbyPagesLimit: number = 2;
}
