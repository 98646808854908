import { AppConfig } from "app-config";
import { ICartProduct } from "domain/Cart/ICartProduct";
import { EventAggregator } from "aurelia-event-aggregator";
import { ProductService } from "services/product/product-service";
import { autoinject, LogManager, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { IProduct } from "domain/Product/IProduct";
import { IStockItem } from "domain/Product/IStockItem";
import { TKEvent } from "utils/enums/TKEvent";
import { IWarehouse } from "domain/Client/IWarehouse";
import { PriceHelper } from "utils/helpers/price-helper";

export const log = LogManager.getLogger("app.components.stock");

@autoinject
export class StockCustomElement {
  @bindable product: IProduct | ICartProduct;
  @bindable warehouses: IWarehouse[];
  isInStock = Utils.isInStock;

  constructor(
    private i18n: I18N,
    private productService: ProductService,
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator
  ) {}

  attached() {
    if (this.product == null) {
      Utils.showErrorToast(log, this.i18n.tr("components.stock.errors.load"));
      return;
    }
  }

  getValue(stock: string): number {
    switch (stock) {
      case "e":
        return 11;
      case "t":
        return -2;
      case "l":
        return -1;
      default:
        return Number(stock);
    }
  }

  takeIntoStockUserWish(productCode: string) {
    const confirmation = confirm(
      this.i18n.tr("components.products.messages.confirm-take-into-stock-wish")
    );

    if (confirmation == false) return;

    this.productService
      .postProductStockRequest(productCode)
      .then(() => {
        Utils.showSuccessToast(
          this.i18n.tr("components.products.messages.take-into-stock-wish-received")
        );
      })
      .catch(() => {
        Utils.showErrorToast(log, this.i18n.tr("components.products.errors.take-into-stock-wish"));
      });
  }

  getStockQuantity(warehouse: IWarehouse): string {
    const stockItem = this.product.stock.find(x => x.warehouse.id === warehouse.id);
    return stockItem?.quantity ?? "0";
  }

  updateStockFromWarehouse(warehouse: IWarehouse) {
    let stockItem = this.product.stock.find(x => x.warehouse.id === warehouse.id);

    if (!stockItem) {
      stockItem = {
        warehouse:
          this.warehouses.find(x => x.id == warehouse.id) ??
          this.warehouses.find(x => x.id == 0) ??
          this.warehouses[0],
        quantity: "0",
        prices: this.product.prices ?? [],
      };
      PriceHelper.formatStockPrices([stockItem], this.appConfig.userSettings, this.i18n);
    }

    this.updateStock(stockItem);
  }

  updateStock(stockItem: IStockItem) {
    if (!stockItem.warehouse.isAllowed) return;

    this.product.selectedStock = stockItem;

    if ("cartProductId" in this.product)
      this.eventAggregator.publish(TKEvent.cartProductsWarehouseChanged, this.product); // TODO rethink this
  }

  allWarehousesNotAllowed() {
    return this.product.stock.length > 0 && this.product.stock.every(x => !x.warehouse.isAllowed);
  }

  otherStockWarningNeeded(warehouseId: number) {
    const userWarehouseId = this.appConfig.userSettings.warehouseId;
    const isCentral = warehouseId === 1;
    const isKadaka = warehouseId === 3;
    const isTartu = warehouseId === 21;

    return !(warehouseId === userWarehouseId || isCentral || isKadaka || isTartu);
  }
}
