import { TKEvent } from "utils/enums/TKEvent";
import { SearchType } from "utils/enums/SearchType";
import { EventAggregator } from "aurelia-event-aggregator";
import { AppConfig } from "app-config";
import { Router, NavigationInstruction, PipelineResult } from "aurelia-router";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { Utils } from "utils/helpers/utils";

export const log = LogManager.getLogger("app.search.search-bar");

@autoinject
export class SearchBar {
  // Main variables
  @observable query: string;
  @observable type: SearchType;
  page = 1;

  // Search delay variables
  searchDelayMilliseconds = 500;
  searchTimer: NodeJS.Timeout;

  // Other variables
  hasFocus = true;

  constructor(
    private router: Router,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      if (this.type === undefined)
        this.type = this.appConfig.userSettings.defaultSearchLimitation;
    });
    this.eventAggregator.subscribe(
      "router:navigation:success",
      (event: {
        instruction: NavigationInstruction;
        result: PipelineResult;
      }) => {
        const currentRoute = event.instruction.config.name;
        const currentParams = event.instruction.params;

        this.updateFromRoute(currentRoute, currentParams);
      }
    );
  }

  activate() {
    const instruction = this.router.currentInstruction.getAllInstructions()[0];
    this.updateFromRoute(instruction.config.name, instruction.params);
  }

  // #region Observables

  queryChanged(newQuery: string, oldQuery: string) {
    // value is set from undefined to something for the first time
    if (oldQuery === undefined)
      return;

    const query = newQuery.trim();
    clearTimeout(this.searchTimer);

    if (query.length == 0) {
      if (this.router.currentInstruction.config.name == "searchResult")
        this.router.navigateToRoute("home");

      return;
    }

    if (query.length < 3)
      return;

    this.page = 1; // if the query changes, we want to make sure the page is reset
    this.delayedSearch(query, this.page, this.type);
  }

  typeChanged(newType: SearchType, oldType: SearchType) {
    if (oldType === undefined)
      return;

    this.delayedSearch(this.query, this.page, newType);
  }

  // #endregion

  // #region ACTIONS

  delayedSearch(query: string, page: number, type: SearchType) {
    if (query.length < 3) return;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(function () {
      that.search(query, page, type);
    }, this.searchDelayMilliseconds);
  }

  search(query: string, page: number, type: SearchType) {
    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(query),
      page: page,
      type: type,
    });
  }

  // #endregion

  // #region HELPERS

  updateFromRoute(currentRoute: string | undefined, currentParams: any) {
    if (!currentRoute)
      return;

    if (currentRoute == "searchResult") {
      this.query = decodeURIComponent(currentParams.query);
      // make sure these are numbers
      this.page = parseInt(currentParams.page ?? "1");
      this.type = parseInt(currentParams.type ?? SearchType.All);
    } else {
      this.query = "";

      if (currentRoute == "home") this.hasFocus = true;
    }
  }

  // #endregion
}
