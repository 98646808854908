import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { Router } from 'aurelia-router';
import { bindable, autoinject, LogManager } from 'aurelia-framework';
import { CarMenuLevel } from 'utils/enums/CarMenuLevel';
import { IBaseVehicle } from 'domain/Car/IBaseVehicle';

export const log = LogManager.getLogger('app.components.car-category-menu');

@autoinject
export class CarCategoryMenuCustomElement {
  @bindable categoryId: number;
  @bindable categoryName: string;

  @bindable car: IBaseVehicle;
  @bindable level: CarMenuLevel;

  filterPlaceholder: string;
  @bindable filterValue: string;

  hasFocus = true;

  constructor(
    private router: Router,
    private i18n: I18N
  ) { }

  bind() {
    switch (this.level) {
      case CarMenuLevel.Manufacturers:
        this.filterPlaceholder = this.i18n.tr('pages.manufacturers.hint');
        break;
      case CarMenuLevel.Models:
        this.filterPlaceholder = this.i18n.tr('pages.models.hint');
        break;
      case CarMenuLevel.Vehicles:
        this.filterPlaceholder = this.i18n.tr('pages.vehicles.hint');
        break;
    }
  }

  // #region ACTIONS

  openManufacturers() {
    this.router.navigateToRoute('categoryManufacturers', { categoryId: this.categoryId });
  }

  openModels() {
    this.router.navigateToRoute('categoryModels', {
      categoryId: this.categoryId,
      manufacturer: this.car.model.manufacturer.id != 0
        ? this.car.model.manufacturer.id
        : Utils.encode(this.car.model.manufacturer.name)
    });
  }

  openVehicles() {
    this.router.navigateToRoute('categoryVehicles', {
      categoryId: this.categoryId,
      manufacturer: this.car.model.manufacturer.id != 0
        ? this.car.model.manufacturer.id
        : Utils.encode(this.car.model.manufacturer.name),
      model: Utils.encode(this.car.model.name)
    });
  }

  openProducts() {
    this.router.navigateToRoute('categoryProducts', {
      categoryId: this.categoryId,
      page: 1,
      vehicleId: this.car.id
    });
  }

  goBack() {
    switch (this.level) {
      case CarMenuLevel.Models:
        this.openManufacturers();
        break;
      case CarMenuLevel.Vehicles:
        this.openModels();
        break;
      case CarMenuLevel.Products:
        this.openVehicles();
        break;
    }
  }

  // #endregion
}
