import { IProduct } from 'domain/Product/IProduct';
import { observable } from 'aurelia-framework';
import { LogManager } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';

export var log = LogManager.getLogger('app.components.gallery');

export class GalleryCustomElement {
  @bindable @observable products: IProduct[];
}
