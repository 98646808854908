import { I18N } from "aurelia-i18n";
import { IWarehouse } from "domain/Client/IWarehouse";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import {
  LogManager,
  autoinject,
  bindable,
  observable,
} from "aurelia-framework";
import { ICart } from "domain/Cart/ICart";
import { ReturnService } from "services/sale/return-service";
import printJS from "print-js";

export const log = LogManager.getLogger("app.components.return.index");

@autoinject
export class ReturnIndexCustomElement {
  warehouses: IWarehouse[];

  returnCart: ICart;
  error: string;

  @observable({ changeHandler: "queryChanged" }) searchText = "";
  @observable({ changeHandler: "queryChanged" }) searchDateFrom = "";
  @observable({ changeHandler: "queryChanged" }) searchDateTo = "";

  @bindable comment = "";
  // warehouseId: transportId
  selectedTransportsForWarehouses = new Map<string, string>();

  totalWithoutVat = 0;
  totalVat = 0;
  totalWithVat = 0;

  options = {
    showImages: false,
    showEan: false,
    showStock: false,
    showWarehouse: false,
    showReason: true,
    showDisplayPrice: false,
  };

  constructor(
    private returnService: ReturnService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N
  ) {
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateTotal();
    });
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadReturn();
    });
  }

  attached() {
    if (this.appConfig.userSettings) this.loadReturn();
  }

  // #region LISTENERS, OBSERVABLES

  updateTotal() {
    if (this.returnCart == undefined || this.returnCart.products == undefined)
      return;

    this.totalWithoutVat = 0;

    this.returnCart.products.forEach((p) => {
      if (p.finalPrice != null)
        this.totalWithoutVat += p.finalPrice * p.quantity;
    });
    this.totalWithoutVat = Utils.round(this.totalWithoutVat);

    this.totalVat = Utils.round(this.totalWithoutVat * AppConfig.vatRate);
    this.totalWithVat = this.totalWithoutVat + this.totalVat;
  }

  queryChanged(newValue: string, oldValue: string) {
    // Required value haven't been set yet
    if (oldValue === undefined) return;

    const dateRegex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])$/;

    if (this.searchDateFrom && !this.searchDateFrom.match(dateRegex)) return;

    if (this.searchDateTo && !this.searchDateTo.match(dateRegex)) return;

    const params = {
      page: 1,
    } as any;

    if (this.searchText != "") params.query = Utils.encode(this.searchText);

    if (this.searchDateFrom != "")
      params.dateFrom = Utils.encode(this.searchDateFrom);

    if (this.searchDateTo != "")
      params.dateTo = Utils.encode(this.searchDateTo);

    this.router.navigateToRoute("invoiceIndex", params);
  }

  // #endregion

  // #region LOAD

  loadReturn() {
    this.returnService
      .fetchCurrent()
      .then((result) => {
        this.returnCart = result;

        this.updateTotal();
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  sendReturn() {
    let cancel = false;
    this.returnCart.products.forEach((product) => {
      if (cancel) return;

      if (!product.comment || !product.comment.trim()) cancel = true;
    });

    if (cancel) {
      Utils.showErrorToast(
        log,
        this.i18n.tr("pages.returns.errors.cart.product-reason-missing")
      );
      return;
    }

    this.returnService
      .sendReturn(this.comment)
      .then(() => {
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
        this.eventAggregator.publish(TKEvent.returnSent);

        const returnId = this.returnCart.id;

        this.loadReturn();

        if (confirm(this.i18n.tr("pages.returns.messages.print-message")))
          this.printMessage(returnId);
      })
      .catch(() =>
        Utils.showErrorToast(log, this.i18n.tr("pages.returns.errors.cart.send"))
      );
  }

  clearReturn() {
    if (!confirm(this.i18n.tr("pages.returns.messages.confirm-clear"))) return;

    this.returnService.clearReturnCart().then(() => {
      this.loadReturn();
      this.eventAggregator.publish(TKEvent.cartProductsChanged);
    });
  }

  // #endregion

  // #region HELPERS

  printMessage(id: number) {
    this.returnService.fetchReturnText(id).then((result) => {
      printJS({ printable: result, type: "raw-html" });
    });
  }

  // #endregion
}
