import { IWarehouse } from 'domain/Client/IWarehouse';
import { TKEvent } from 'utils/enums/TKEvent';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IUserSettings } from 'domain/Settings/IUserSettings';
import { ICar } from 'domain/Car/ICar';
import { LogManager, autoinject } from "aurelia-framework";
import { AureliaConfiguration } from 'aurelia-configuration';
import { jwtDecode } from 'jwt-decode';
import { IContractSettings } from 'domain/Settings/IContractSettings';
import { ISettings } from 'domain/Settings/ISettings';
import * as Sentry from '@sentry/browser';

export const log = LogManager.getLogger('app.config');

@autoinject
export class AppConfig {
  // Configure stuff
  public static vatRate = 0.22;
  public datetimeFormat = "DD.MM.YYYY HH:mm";
  public dateFormat = "DD.MM.YYYY";

  // API and user stuff
  public apiUrl: string;
  public jwt?: string = localStorage.getItem("token") ?? undefined;
  public decodedJwt?: any;
  public email?: string;

  // Remember stuff
  public cars: ICar[] = this.getAllCars();
  public prevRoute: string;

  public userSettings: IUserSettings;
  public contractSettings: IContractSettings;

  public transportOptions: Map<string, string>;
  public warehouses: IWarehouse[];

  // Cannot use services here, because they depend on AppConfig
  constructor(
    config: AureliaConfiguration,
    private eventAggregator: EventAggregator
  ) {
    this.apiUrl = config.get('apiUrl');

    this.updateEmail();

    if (config.get('debug'))
      LogManager.setLevel(LogManager.logLevel.debug);
  }

  setToken(jwt: string, rememberMe: boolean): void {
    this.jwt = jwt;
    this.decodedJwt = jwtDecode(this.jwt);

    if (rememberMe)
      localStorage.setItem("token", jwt);

    this.updateEmail();
  }

  removeToken(): void {
    this.jwt = undefined;
    this.decodedJwt = undefined;
    this.email = undefined;

    localStorage.removeItem("token");
  }

  setSettings(settings: ISettings) {
    this.userSettings = settings.userSettings;
    this.contractSettings = settings.contractSettings;

    this.eventAggregator.publish(TKEvent.userSettingsLoaded);
  }

  setWarehouses(warehouses: IWarehouse[]) {
    this.warehouses = warehouses;
    this.eventAggregator.publish(TKEvent.warehousesLoaded);
  }

  setTransportOptions(transportOptions: Map<string, string>) {
    this.transportOptions = transportOptions;
    this.eventAggregator.publish(TKEvent.transportOptionsLoaded);
  }

  updateEmail() {
    if (!this.decodedJwt)
      return;

    const email = this.email = this.decodedJwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];

    Sentry.setUser({ email: email });
  }

  isTokenExpired() {
    if (!this.decodedJwt)
      return false;

    const now = Date.now().valueOf() / 1000;

    // Consider token expired if it expires within the next 10 seconds
    return this.decodedJwt.exp < now + 10;
  }

  addCar(car: ICar) {
    // Remove the same car if it has been previously searched
    const sameCarIndex = this.cars.findIndex(x => x.id == car.id);
    if (sameCarIndex >= 0)
      this.cars.splice(sameCarIndex, 1);

    this.cars.push(car);

    localStorage.setItem('cars', JSON.stringify(this.cars));
  }

  getSelectedCar(): ICar | null {
    return this.cars.length == 0
      ? null
      : this.cars[this.cars.length - 1];
  }

  getAllCars(): ICar[] {
    const storedCars = localStorage.getItem('cars');

    if (!storedCars) {
      return [];
    }

    return JSON.parse(storedCars) as ICar[];
  }
}
