import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";

@autoinject
export class CopyToClipboardCustomElement {
  @bindable text: string;

  constructor(
    private i18n: I18N
  ) {}
  
  copyToClipboard() {
    navigator.clipboard.writeText(this.text)
    Utils.showSuccessToast(this.i18n.tr('components.products.messages.code-copied-to-clipboard'));
  }
}
