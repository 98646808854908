import { Utils } from "utils/helpers/utils";
import {
  LogManager,
  autoinject,
  bindable,
} from "aurelia-framework";
import { IProduct } from "domain/Product/IProduct";
import { ProductService } from 'services/product/product-service';
import { I18N } from 'aurelia-i18n';

export const log = LogManager.getLogger('app.components.report-button');

@autoinject
export class ReportButtonCustomElement {
  @bindable product: IProduct;

  constructor(
    private i18n: I18N,
    private productService: ProductService
  ) {}

  sendProductReport(productCode: string, reportText: string) {
    this.productService
    .postReportError(productCode, reportText)
    .then(() => {
      Utils.showSuccessToast(
        this.i18n.tr("components.products.messages.error-reported")
      );
    })
    .catch(() => {
      Utils.showErrorToast(
        log,
        this.i18n.tr("components.products.errors.report-error")
      );
    });
  }
}
