import { I18N } from 'aurelia-i18n';
import { observable } from 'aurelia-framework';
import { TKEvent } from 'utils/enums/TKEvent';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import noUiSlider, { PipsMode } from "nouislider";
import wNumb from 'wnumb';
import { Utils } from 'utils/helpers/utils';

@autoinject
export class ProductFilterCustomElement {
  @bindable @observable categoryId: number;
  @bindable possibleFilters: any;
  @bindable chosenFilters: any;

  isChosenFiltersEmpty: boolean = true;

  constructor(
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) { }

  attached() {
    this.updateIsChosenFiltersEmpty();
    this.sortPossibleFilters();
    this.createSliders();
    this.prefillFilters();
  }

  categoryIdChanged(newValue: number, oldValue: number) {
    if (oldValue === undefined)
      return;

    this.resetChosenFilters();
  }


  sortPossibleFilters() {
    let sortedPossibleFilters: any = {};

    for (let key in this.possibleFilters) {
      const filtersAreNumbers = this.areAllNumbers(this.possibleFilters[key]);

      sortedPossibleFilters[key] = filtersAreNumbers
        ? this.possibleFilters[key].sort((a: number, b: number) => a - b)
        : this.possibleFilters[key].sort();
    }

    this.possibleFilters = sortedPossibleFilters;
  }

  areAllNumbers(array: []) {
    return array.every((value: any) => !isNaN(value));
  }

  createSliders() {
    const containers = document.querySelectorAll('.slider');
    containers.forEach(container => {
      const key = container.getAttribute('data-key')!;
      const filterValues = this.possibleFilters[key];

      const min = Math.min(...filterValues);
      const max = Math.max(...filterValues);

      this.createSlider(container, min, max);
    });
  }

  createSlider(slider: any, min: number, max: number) {
    noUiSlider.create(slider, {
      start: [min, max],
      range: { min, max },
      connect: true,
      tooltips: true,
      direction: 'rtl',
      orientation: 'vertical',
      format: wNumb({ decimals: 0 }),
      step: 1,
      pips: {
        mode: PipsMode.Positions as any,
        values: [0, 25, 50, 75, 100],
        stepped: true,
        density: 5
      }
    }).on('change', () => {
      this.chooseFilter(slider);
    });
  }

  prefillFilters() {
    if (this.possibleFilters === undefined || this.chosenFilters === undefined)
      return;

    this.clearFilterBadges();

    Object.entries(this.chosenFilters).forEach(([key, values]: [string, string[]]) => this.prefillFilter(key, values));
  }

  prefillFilter(key: string, values: string[]) {
    let temporaryPossibleFilters = this.possibleFilters;
    this.possibleFilters = {}; // Clear previous filters
    this.possibleFilters = temporaryPossibleFilters;

    const sliders = document.querySelectorAll(`.slider[data-key="${key}"]`);

    if (sliders.length > 0)
      this.prefillSliders(sliders.item(0), key, values);
    else
      this.prefillCheckboxes(key, values);
  }

  prefillSliders(target: Element, key: string, values: string[]) {
    const slider = (target as any).noUiSlider;

    const chosenMin = Math.min(...values as []);
    const chosenMax = Math.max(...values as []);

    const range = slider.options.range;
    if (chosenMin != range.min || chosenMax != range.max)
      this.addFilterBadge(key, `${chosenMin}...${chosenMax}`);

    slider.set([chosenMin, chosenMax]);
  }

  prefillCheckboxes(key: string, values: string[]) {
    let keyIndex = Object.keys(this.possibleFilters).indexOf(key);

    (values as []).forEach((value) => {
      let valueIndex = (this.possibleFilters[key] as []).indexOf(value);

      let checkbox = document.getElementById(`value-${keyIndex}-${valueIndex}`) as HTMLInputElement;
      checkbox.checked = true;
    });

    this.addFilterBadge(key, (values as []).join(", "));
  }

  addFilterBadge(key: string, text: string) {
    const filterBadge = this.getElementByKey(key);
    const keyName = this.i18n.tr('pages.category.filters.' + this.categoryId + '.' + key);

    filterBadge.innerText = `${keyName}: ${text}`;
    filterBadge.style.display = "block";
  }

  clearFilterBadges() {
    const element = document.getElementById("chosen-filters");
    if (!element) return;

    for (let i = 0; i < element.children.length; i++) {
      let child = element.children[i];
      if (child.nodeName == "SPAN")
        this.clearElement(child as HTMLSpanElement);
    }
  }

  clearFilterBadge(key: string) {
    const element = this.getElementByKey(key);
    this.clearElement(element);
  }

  clearElement(element: HTMLElement) {
    if (!element) return;
    element.style.display = "none";
    element.innerHTML = "";
  }

  chooseFilter(target: HTMLElement) {
    let values: string[] = [];
    let key = target.getAttribute('data-key')!;

    this.clearFilterBadge(key);

    if (target.classList.contains("slider")) {
      values = this.getSliderFilterValues(target, key);
    } else {
      values = this.getCheckboxFilterValues(key);
    }

    this.updateChosenFilters(values, key);
  }

  getSliderFilterValues(target: HTMLElement, key: string): string[] {
    const values: string[] = [];

    const chosenValues = (target as any).noUiSlider.get(true);
    const chosenMin = parseInt(chosenValues[0]);
    const chosenMax = parseInt(chosenValues[1]);

    const range = (target as any).noUiSlider.options.range;
    if (chosenMin == range.min && chosenMax == range.max)
      return values;

    this.addFilterBadge(key, `${chosenMin}...${chosenMax}`);

    for (let value of this.possibleFilters[key]) {
      value = Number(value);

      if (value >= chosenMin && value <= chosenMax)
        values.push(value);
    }

    return values;
  }

  getCheckboxFilterValues(key: string): string[] {
    let values: string[] = [];
    let checkedBoxes: NodeListOf<Element> = document.querySelectorAll(`input[data-key="${key}"]:checked`);

    if (checkedBoxes.length == 0)
      return [];

    checkedBoxes.forEach((elements: HTMLInputElement) => values.push(elements.value));
    this.addFilterBadge(key, (values as []).join(", "));

    return values;
  }

  updateChosenFilters(values: string[], key: string) {
    if (this.chosenFilters == undefined)
      this.chosenFilters = {};

    if (values.length == 0)
      delete this.chosenFilters[key];
    else
      this.chosenFilters[key] = values;

    this.updateIsChosenFiltersEmpty();
    this.publishChosenFiltersChanged();
  }

  getElementByKey(key: string) {
    return document.querySelectorAll(`#chosen-filters span[data-key="${key}"]`)[0] as HTMLSpanElement;
  }

  resetChosenFilters() {
    this.chosenFilters = {};
    this.clearFilterBadges();
    this.removeSliders();
    this.publishChosenFiltersChanged();
    this.attached();
  }

  removeSliders() {
    const sliders = document.getElementsByClassName("slider");
    for (var i = 0; i < sliders.length; i++)
      (sliders.item(i) as any).noUiSlider.destroy();
  }

  publishChosenFiltersChanged() {
    this.eventAggregator.publish(TKEvent.chosenFiltersChanged, {
      categoryId: this.categoryId,
      chosenFilters: this.chosenFilters
    });
  }

  updateIsChosenFiltersEmpty() {
    this.isChosenFiltersEmpty = Utils.isObjectEmpty(this.chosenFilters);
  }
}

