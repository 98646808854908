import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { IRvsOrder } from "domain/Sale/IRvsOrder";
import { LogManager, autoinject, bindable } from "aurelia-framework";
import $ from "jquery";
import "bootstrap";
import { OrderService } from "services/sale/order-service";
import { Utils } from "utils/helpers/utils";
import { EventAggregator } from "aurelia-event-aggregator";
import { TKEvent } from "utils/enums/TKEvent";
import { Collapse } from "bootstrap";

export const log = LogManager.getLogger("app.components.order-list");

@autoinject
export class OrderListCustomElement {
  @bindable orders: IRvsOrder[];

  options = {
    showInvoiceId: false,
    showWarehouse: false,
    showTransport: false,
    showAddToCart: true,
    showAddToReturn: true,
    showConvertToCart: true,
  };

  constructor(
    private orderService: OrderService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private i18n: I18N
  ) {}

  attached() {
    this.orders.forEach((order) => {
      order.originalId = order.originalId.replace("*INTERNET*", "");
    });
  }

  convertToCart(order: IRvsOrder) {
    this.orderService
      .convertRvsOrderToCart(order.id)
      .then(() => {
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
        Utils.showSuccessToast(
          this.i18n.tr("pages.orders.messages.convert-to-cart")
        );
      })
      .catch((error) =>
        Utils.showErrorToast(
          log,
          Utils.getErrorMessage(error, this.i18n)
        )
      );
  }

  toggleProductList(target: HTMLElement, order: IRvsOrder) {
    $(() => {
      // Check if child element was clicked instead
      if (
        $(target).prop("tagName") != "TD" &&
        $(target).prop("tagName") != "TR"
      )
        return;

      const productListElement = document.querySelector(
        `[data-product-list="${order.id}"]`
      );
      if (productListElement)
        new Collapse(productListElement, {
          parent: "#accordion-order",
        }).toggle();
    });
  }

  canConvertToCart(order: IRvsOrder): boolean {
    return order.products.some((p) => Number(p.amount) > 0);
  }
}
