import { autoinject, LogManager, bindable } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";
import { TKEvent } from "utils/enums/TKEvent";
import { AppConfig } from "app-config";

export const log = LogManager.getLogger("app.components.add-to-cart");

@autoinject
export class AddToCartCustomElement {
  @bindable product: IProduct;

  constructor(
    private cartProductService: CartProductService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private appConfig: AppConfig,
    private i18n: I18N
  ) {}

  addToCart(product: IProduct) {
    this.cartProductService
      .post(this.getRequestData(product))
      .then(() => {
        Utils.showSuccessToast(this.i18n.tr("components.products.messages.added-to-cart"));

        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch(() => {
        Utils.showErrorToast(log, this.i18n.tr("components.products.errors.add-to-cart"));
      });
  }

  getRequestData(product: IProduct): any {
    const data = {
      id: product.id,
      foundBy: product.foundBy,
      code: product.code,
      quantity: parseInt(product.toCartQuantity ?? "1"),
      warehouseId: product.selectedStock?.warehouse?.id ?? 0,
    } as any;

    if (product.selectedStock?.warehouse?.isExternal) {
      data.warehouseId = 0;
      data.externalWarehouseId = product.selectedStock?.warehouse?.id;
    }

    return data;
  }

  otherStockWarningNeeded(warehouseId: number) {
    const userWarehouseId = this.appConfig.userSettings.warehouseId;
    const isCentralWarehouse = warehouseId === 1;
    const isMustamaeWarehouse = warehouseId === 3;

    return warehouseId !== userWarehouseId && !isCentralWarehouse && !isMustamaeWarehouse;
  }
}
