import { ICar } from 'domain/Car/ICar';
import { AppConfig } from 'app-config';
import { Router } from 'aurelia-router';
import { LogManager, autoinject } from 'aurelia-framework';
import { Utils } from 'utils/helpers/utils';

export var log = LogManager.getLogger('app.components.car-tree');

@autoinject
export class CarTree {
  constructor(
    private router: Router,
    private appConfig: AppConfig, // used by HTML
  ) { }

  // #region ACTIONS

  carButtonClicked() {
    if (this.router.currentInstruction.getAllInstructions()
      .find(instruction => instruction.config.name == 'carManufacturers')) {
      this.router.navigateBack();
    } else {
      this.router.navigateToRoute('carManufacturers');
    }
  }

  loadCatalogueFor(car: ICar) {
    let query = car.licencePlate || car.vin;

    this.router.navigateToRoute('catalogueList', {
      vehicleId: car.id,
      query: Utils.encode(query)
    });
  }

  // #endregion
}
