import { EventAggregator } from "aurelia-event-aggregator";
import { TKEvent } from "utils/enums/TKEvent";
import { ReturnService } from "services/sale/return-service";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { AppConfig } from "app-config";
import { ICart } from "domain/Cart/ICart";
import { Utils } from "utils/helpers/utils";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { Router } from "aurelia-router";
import $ from "jquery";
import "bootstrap";
import printJS from "print-js";
import { Collapse } from "bootstrap";

export const log = LogManager.getLogger("app.components.return.list");

@autoinject
export class ReturnListCustomElement {
  returns: ICart[] | undefined;
  error: string;

  @observable({ changeHandler: "queryChanged" }) currentPage = 1;
  lastPage = 1;

  options = {
    showInvoiceId: true,
    showWarehouse: false,
    showTransport: false,
    showAddToCart: false,
    showAddToReturn: false,
  };

  constructor(
    private returnService: ReturnService,
    private router: Router,
    private appConfig: AppConfig, // used by html
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadReturns();
    });
    this.eventAggregator.subscribe(TKEvent.returnSent, () => {
      this.loadReturns();
    });
  }

  attached() {
    if (this.appConfig.userSettings) this.loadReturns();
  }

  queryChanged(newValue: number | string, oldValue: number | string) {
    // Required value haven't been set yet
    if (oldValue === undefined) return;

    // Clear the old result so the loading icon could activate again
    this.returns = undefined;

    // Since navigating to the same route doesn't actually refresh the page
    if (this.appConfig.userSettings) this.loadReturns();
  }

  // #region LOAD

  loadReturns() {
    this.returnService
      .fetchReturns(this.currentPage)
      .then((result) => {
        this.returns = result.items;
        this.currentPage = result.currentPage;
        this.lastPage = result.lastPage;

        this.returns.forEach((returnCart) => {
          // make it UTC ISO8601
          returnCart.closedDateTime = new Date(returnCart.closedDateTime + "Z");

          returnCart.total = 0;
          returnCart.products.forEach(
            (product) =>
              (returnCart.total += (product.finalPrice ?? 0) * product.quantity)
          );
        });

        this.sortedListener();
        Utils.loadSortable();
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  searchProduct(query: string) {
    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(query),
      page: 1,
      type: SearchType.Precise,
    });
  }

  toggleProductList(target: HTMLElement, returnCart: ICart) {
    $(() => {
      // Check if child element was clicked instead
      if (
        $(target).prop("tagName") != "TD" &&
        $(target).prop("tagName") != "TR"
      )
        return;

      const collapseElement = document.querySelector(
        `[data-product-list="${returnCart.id}"]`
      );

      if (collapseElement)
        return new Collapse(collapseElement, {
          parent: "#accordion-invoice",
        }).toggle();
    });
  }

  // #endregion

  // #region HELPERS

  sortedListener() {
    $(() => {
      $(".sortable").on("sorted", function () {
        // Move product list rows along with their parent order row when sorted
        $(this)
          .find(".product-list-row")
          .each(function () {
            $(this).insertAfter(
              $(`.return-row[data-return="${$(this).attr("data-return")}"]`)
            );
          });
      });
    });
  }

  printMessage(returnCart: ICart) {
    returnCart.loading = true;
    this.returnService
      .fetchReturnText(returnCart.id)
      .then((result) => {
        printJS({ printable: result, type: "raw-html" });
      })
      .finally(() => (returnCart.loading = false));
  }

  // #endregion
}
