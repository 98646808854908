import { I18N } from "aurelia-i18n";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager, autoinject, observable, bindable } from "aurelia-framework";
import { IProduct } from "domain/Product/IProduct";
import { ProductService } from "services/product/product-service";
import { PriceHelper } from "utils/helpers/price-helper";
import { TKEvent } from "utils/enums/TKEvent";
import $ from "jquery";
import { Collapse, Popover } from "bootstrap";
import { SearchOrder } from "utils/enums/SearchOrder";
import { isEmpty } from "lodash";
import { WarehouseService } from "services/client/warehouse-service";
import { IWarehouse } from "domain/Client/IWarehouse";

export const log = LogManager.getLogger("app.components.product-list");

@autoinject
export class ProductListCustomElement {
  @bindable @observable products: IProduct[];
  @bindable currentPage = 1;
  @bindable lastPage = 1;
  @bindable query = "";
  @bindable @observable vehicleId: number | undefined;
  @bindable @observable categoryId: number | undefined;
  @bindable orderBy = SearchOrder.Default;
  @bindable orderReversed = false;
  pageHasEan = false;
  pageHasBrand = false;
  pageHasInPackage = false;

  colSpan = 9;
  imageRowspan = 2;

  @bindable options = {
    showAmount: false,
    showSum: false,
    showAddToCart: false,
  };

  warehouses: IWarehouse[] = [];

  // https://discourse.aurelia.io/t/have-access-to-the-values-of-an-enum-in-a-html-page/831
  SearchOrder = SearchOrder;

  constructor(
    private productService: ProductService,
    private warehouseService: WarehouseService,
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.formatPrice();
    });
  }

  attached() {
    $(() => {
      this.colSpan = $("#product-list-header-row").children().length;
    });
  }

  // #region MAIN

  productsChanged() {
    this.processResult();
  }

  // When the result is processed for the first time, vehicleId is not available yet
  // So we need to update it once when the vehicle id is loaded
  vehicleIdChanged(newValue: string, oldValue: string) {
    if (oldValue !== undefined) return;

    this.products.forEach(product => {
      this.loadInfo(product);
    });
  }

  // When the result is processed for the first time, categoryId is not available yet
  // So we need to update it once when the category id is loaded
  categoryIdChanged(newValue: string, oldValue: string) {
    if (oldValue !== undefined) return;

    this.products.forEach(product => {
      this.loadImagesWithCategory(product);
      this.loadInfo(product);
    });
  }

  processResult() {
    this.loadSortables();
    this.moveRowsListener();
    this.formatPrice();

    this.imageRowspan =
      this.appConfig.userSettings.detailsInProductPage ||
      this.appConfig.userSettings.imagesInProductPage
        ? 2
        : 1;

    this.renderColumnsConditionally();
    if (this.products) {
      this.getWarehouses().finally(() => {
        this.products.forEach(product => {
          this.fillDefaultSelectedStock(product);
          this.loadExternalStock(product);

          // Fill out the cart amount input
          if (!Utils.isPositive(product.toCartQuantity)) product.toCartQuantity = "1";

          this.highlightSearchQuery(product);

          this.loadThumbnail(product);
          this.loadImages(product);
          this.loadInfo(product);
        });
      });
    }
  }
  // #endregion

  // #region LOAD
  loadSortables() {
    $(() => {
      const bsIconsClasses = ".bi-filter, .bi-sort-down, .bi-sort-up";
      $("th[data-sort]").children(bsIconsClasses).remove();

      const headers = $("th[data-sort]:not(:has(i))");
      headers.append(' <i class="bi-filter"></i>');

      const clickedElement = $("th[data-sort=" + this.orderBy + "]").find(bsIconsClasses);
      clickedElement.addClass(this.orderReversed ? "bi-sort-up" : "bi-sort-down");
    });
  }

  getWarehouses(): Promise<IWarehouse[] | void> {
    if (this.warehouses.length > 0) return new Promise(resolve => resolve(this.warehouses));

    return this.warehouseService
      .getWarehouses()
      .then(result => {
        this.warehouses = result;
        log.debug("load warehouses", this.warehouses);
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("components.warehouses.errors.load")));
  }

  loadThumbnail(product: IProduct) {
    this.productService
      .fetchThumbnail(product.id)
      .then(result => {
        if (result == "") return;

        product.thumbnail = result;

        // Large thumbnail when hovering
        const thumbnailList = [].slice.call(
          document.querySelectorAll(`[data-image="${product.id}"]`)
        );
        thumbnailList.map(function (popoverTriggerEl: HTMLElement) {
          return new Popover(popoverTriggerEl, {
            content: `<img class="thumbnail-hover-image" src="${product.thumbnail}"></img>`,
            trigger: "hover focus",
            container: $(`[data-image="${product.id}"]`).get(0),
            html: true,
          });
        });
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  loadImages(product: IProduct) {
    this.productService
      .fetchImages(product.id)
      .then(result => {
        product.images = result.concat(product.images ?? []);
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  loadImagesWithCategory(product: IProduct) {
    if (!this.categoryId) return;

    this.productService
      .fetchImagesWithCategory(product.id, this.categoryId)
      .then(result => {
        product.images = result.concat(product.images ?? []);
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  loadInfo(product: IProduct) {
    const oldVehicleId = this.vehicleId;
    const oldCategoryId = this.categoryId;

    let fetch;
    if (this.vehicleId) {
      if (this.categoryId) {
        fetch = this.productService.fetchInfoWithCategory(
          product.id,
          this.vehicleId,
          this.categoryId
        );
      } else {
        fetch = this.productService.fetchInfoWithCar(product.id, this.vehicleId);
      }
    } else {
      fetch = this.productService.fetchInfo(product.id);
    }

    fetch
      .then(result => {
        // Check if vehicleId/categoryId has showed up while waiting for response - if yes, then abort
        if (this.vehicleId != oldVehicleId || this.categoryId != oldCategoryId) return;

        if (Utils.isArrayEmpty(result)) result = [];

        product.info = result;
        this.extractEanFromInfo(product);
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  loadDescription(product: IProduct) {
    const oldCategoryId = this.categoryId;

    const fetch = this.categoryId
      ? this.productService.fetchDescriptionWithCategory(product.id, this.categoryId)
      : this.productService.fetchDescription(product.id);

    fetch
      .then(result => {
        // Check if categoryId has showed up while waiting for response - if yes, then abort
        if (this.categoryId != oldCategoryId) return;

        product.description = result ? result : null;
      })
      .catch(error => {
        product.description = null;
        Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n));
      })
      .finally(() => {
        this.afterLoading(product, "loading-description");
      });
  }

  loadDocuments(product: IProduct) {
    const oldCategoryId = this.categoryId;

    const fetch = this.categoryId
      ? this.productService.fetchDocumentsWithCategory(product.id, this.categoryId)
      : this.productService.fetchDocuments(product.id);

    fetch
      .then(result => {
        // Check if categoryId has showed up while waiting for response - if yes, then abort
        if (this.categoryId != oldCategoryId) return;

        product.documents = Utils.isArrayEmpty(result) ? [] : result;
      })
      .catch(error => {
        product.documents = [];
        Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n));
      })
      .finally(() => {
        this.afterLoading(product, "loading-documents");
      });
  }

  loadOeNumbers(product: IProduct) {
    this.productService
      .fetchOeNumbers(product.id)
      .then(result => {
        if (Utils.isObjectEmpty(result)) result = [];

        product.oeNumbers = Object.entries(result);
      })
      .catch(error => {
        product.oeNumbers = [];
        Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n));
      })
      .finally(() => {
        this.afterLoading(product, "loading-oe-numbers");
      });
  }

  loadCars(product: IProduct) {
    this.productService
      .fetchCars(product.id)
      .then(result => {
        if (Utils.isArrayEmpty(result)) result = [];

        product.cars = result;
      })
      .catch(error => {
        product.cars = [];
        Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n));
      })
      .finally(() => {
        this.afterLoading(product, "loading-cars");
      });
  }

  loadExternalStock(product: IProduct) {
    this.productService
      .fetchExternalStock(product.id)
      .then(result => {
        if (Utils.isArrayEmpty(result)) result = [];
        product.externalStock = result;
        PriceHelper.formatStockPrices(
          product.externalStock,
          this.appConfig.userSettings,
          this.i18n
        );
      })
      .catch(error => {
        log.error("Error fetching external stock:", error);
      });
  }

  fillDefaultSelectedStock(product: IProduct) {
    let stockItem = product.stock.find(
      x => x.warehouse.id == this.appConfig.userSettings.warehouseId
    );

    if (!stockItem) {
      stockItem = this.getFallbackStock(product);
      PriceHelper.formatStockPrices([stockItem], this.appConfig.userSettings, this.i18n);
    }

    product.selectedStock = stockItem;
  }

  getFallbackStock(product: IProduct) {
    return {
      warehouse:
        this.warehouses.find(x => x.id == this.appConfig.userSettings.warehouseId) ??
        this.warehouses.find(x => x.id == 0) ??
        this.warehouses[0],
      quantity: "0",
      prices: product.prices ?? [],
    };
  }

  extractEanFromInfo(product: IProduct) {
    if (product.ean.length > 0) return;

    const eanIndex = product.info?.findIndex(x => x[0] == "EAN") ?? -1;
    if (eanIndex < 0) return;

    const ean = product.info?.at(eanIndex)?.at(1);
    if (!ean) return;

    product.ean = ean;
    product.info?.splice(eanIndex, 1);
  }

  // #endregion

  // #region ACTIONS

  changeOrder(clickedElement: Element) {
    if (
      !clickedElement.getAttribute("data-sort") &&
      !clickedElement.classList.contains("bi-filter")
    )
      return;

    // If clicked on sort icon, then use it's parent as base
    if (clickedElement.classList.contains("bi-filter"))
      clickedElement = clickedElement.parentElement ?? clickedElement;

    const orderBy = parseInt(clickedElement.getAttribute("data-sort") ?? "0");
    let orderReversed = !this.orderReversed;

    // Reset orderReversed value to false if another column is being ordered
    if (orderBy != this.orderBy) orderReversed = false;

    this.orderReversed = orderReversed;
    this.orderBy = orderBy;
  }

  toggleExtraRow(target: HTMLElement, product: IProduct) {
    $(() => {
      // Check if child element was clicked instead
      if ($(target).prop("tagName") != "TD" && $(target).prop("tagName") != "TR") return;

      const productWrappers = $(`.clickable[data-product-id="${product.id}"]`);

      // Start loading them if they aren't already loaded
      if (!this.areExtrasLoaded(product)) {
        // If the element has any of these classes, we're still waiting for the results
        if (
          productWrappers.is(
            ".loading-description, .loading-documents, .loading-oe-numbers, .loading-cars"
          )
        )
          return;

        productWrappers.addClass(
          "loading-description loading-documents loading-oe-numbers loading-cars"
        );

        this.loadDescription(product);
        this.loadDocuments(product);
        this.loadOeNumbers(product);
        this.loadCars(product);

        return;
      }

      this.tryOpenExtraRow(product, productWrappers);
    });
  }

  shouldExtraRowOpen(product: IProduct): boolean {
    const showDetails = this.appConfig.userSettings.detailsInProductPage;
    const showImages = this.appConfig.userSettings.imagesInProductPage;

    // extra row must be opened, because otherwise we can't print out the message that nothing can be displayed
    if (!showDetails && !showImages) return true;

    return this.anyDataInExtraRow(product);
  }

  anyDataInExtraRow(product: IProduct): boolean {
    const showDetails = this.appConfig.userSettings.detailsInProductPage;
    const showImages = this.appConfig.userSettings.imagesInProductPage;
    const isDataInTable = $("#d-lg-none").is(":hidden");

    // Also check if EAN or package count is hidden or doesn't exist
    const noHiddenData = isDataInTable || (!product.ean && !product.inPackage);
    const noHiddenDetails = showDetails || product.info === null || product.info.length == 0;
    const noDescriptions = product.description === null || product.description === "";
    const noHiddenImages =
      showImages || product.thumbnail === null || product.thumbnail.length == 0;
    const noExtras = !this.isAnyExtras(product);

    return !(noHiddenData && noHiddenDetails && noDescriptions && noHiddenImages && noExtras);
  }

  showGallery(product: IProduct) {
    Utils.showGallery(product);
  }

  // #endregion

  // #region HELPERS

  highlightSearchQuery(product: IProduct) {
    // Highlight search query with <strong>
    $(() => {
      const target = $(
        `[data-product-id="${product.id}"] [data-highlight-query="${product.foundBy}"]`
      );
      if (target.length > 0) {
        const regex = new RegExp(Utils.escapeRegex(this.query), "gi");
        const code = target.html().replace(regex, "<strong>$&</strong>");
        target.html(code);

        if (!target.html().includes("<strong>")) {
          target.html("<strong>" + target.html() + "</strong>");
        }
      }
    });
  }

  isAnyExtras(product: IProduct) {
    return (
      product.cars?.length != 0 || product.documents?.length != 0 || product.oeNumbers?.length != 0
    );
  }

  areExtrasLoaded(product: IProduct) {
    return (
      product.cars !== undefined &&
      product.documents !== undefined &&
      product.oeNumbers !== undefined
    );
  }

  afterLoading(product: IProduct, classToRemove: string) {
    const productWrappers = $(`.clickable[data-product-id="${product.id}"]`);

    productWrappers.removeClass(classToRemove);

    // If the element has any of these classes, we're still waiting for the results
    if (
      productWrappers.is(
        ".loading-description, .loading-documents, .loading-oe-numbers, .loading-cars"
      )
    )
      return;

    this.tryOpenExtraRow(product, productWrappers);
  }

  tryOpenExtraRow(product: IProduct, productWrappers: JQuery<HTMLElement>) {
    $(() => {
      if (!this.anyDataInExtraRow(product))
        $(`[data-extra-not-found="${product.id}"]`).html(
          this.i18n.tr("components.products.errors.no-info-found")
        );

      if (this.shouldExtraRowOpen(product)) {
        const extraRow = document.querySelector(`[data-extra="${product.id}"]`);
        if (extraRow) new Collapse(extraRow, { parent: "#accordion" }).toggle();
      } else {
        productWrappers.removeClass("clickable");
      }
    });
  }

  // Move info and extra rows along with it's parent product row when sorted
  moveRowsListener() {
    $(() => {
      $(".sortable").on("sorted", function () {
        $(this)
          .find("[rowspan-value]")
          .each(function () {
            $(this).attr("rowspan", $(this).attr("rowspan-value") || 2);
          });

        $(this)
          .find("tr.info-row")
          .each(function () {
            $(this).insertAfter(
              `.product-row[data-product-id="${$(this).attr("data-product-id")}"]`
            );
            $(this).children(".product-image-wrapper").remove();
          });
        $(this)
          .find("tr.extra-row")
          .each(function () {
            $(this).insertAfter(
              $(`.info-row[data-product-id="${$(this).attr("data-product-id")}"]`)
            );
          });
      });
    });
  }

  formatPrice() {
    if (!this.products || !this.appConfig.userSettings) return;

    PriceHelper.formatProductsPrices(this.products, this.appConfig.userSettings, this.i18n);
  }

  pageHasEANChecker() {
    for (const product of this.products) {
      if (product.ean || !isEmpty(product.ean)) {
        this.pageHasEan = true;
        return;
      }
    }
  }

  pageHasBrandChecker() {
    for (const product of this.products) {
      if (product.brand || !isEmpty(product.brand)) {
        this.pageHasBrand = true;
        return;
      }
    }
  }

  pageHasInPackageChecker() {
    for (const product of this.products) {
      if (product.inPackage || product.inPackage > 0) {
        this.pageHasInPackage = true;
        return;
      }
    }
  }

  resetConditionalColumnRendering() {
    this.pageHasEan = false;
    this.pageHasBrand = false;
    this.pageHasInPackage = false;
  }

  renderColumnsConditionally() {
    this.resetConditionalColumnRendering();

    this.pageHasEANChecker();
    this.pageHasBrandChecker();
    this.pageHasInPackageChecker();
  }

  // #endregion
}
